<template>
  <div class="reply-class">
    <div class="public-div-border title">标题：{{ form.title }}</div>
    <div class="public-div-border time">时间：{{ form.time }}</div>
    <div class="public-div-border">
      <div class="public-text">提问内容：</div>
      <div class="public-label">
        {{ form.upcontent }}
      </div>
    </div>
    <div class="public-div-border">
      <div class="public-text">期望方案：</div>
      <div class="public-label">
        {{ form.wantcontent }}
      </div>
    </div>
    <div class="public-div-border">
      <div class="public-text">回复内容：</div>
      <div class="public-label">
        <el-input
          type="textarea"
          :rows="5"
          v-model="form.retcontent"
          placeholder="请输入回复内容"
        ></el-input>
      </div>
    </div>
    <div class="public-div-border">
      <div class="public-text">是否公开：</div>
      <div class="public-label">
        <el-radio-group v-model="form.isopen">
          <el-radio label="1">是</el-radio>
          <el-radio label="-1">否</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="upform">
      <el-button size="mini" @click="upserve" type="success">提交</el-button>
      <!-- <el-button size="mini" @click="form = {}">重置</el-button> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$axios
        .get("/appealapi/getc.php?id=" + this.$route.query.id)
        .then((res) => {
          this.$set(this, "form", res.data);
        });
    },
    upserve() {
      let form = new FormData();
      for (let i in this.form) {
        form.append(i, this.form[i]);
      }
      this.$axios.post("/appealapi/upret.php", form).then((res) => {
        if (res.data == "success") {
          this.$router.push('/backstage')
        } else {
          this.$message.error(res.data);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.reply-class {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .title {
    font-size: 22px;
    font-weight: 900;
  }
  .time {
    font-size: 14px;
    color: #a9a9a9;
  }
}
</style>